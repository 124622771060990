@tailwind base;
@tailwind components;
@tailwind utilities;
/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  html {
    font-family: "Inter", sans-serif;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .text-gradient {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  .font-inter {
    font-family: "Inter", sans-serif;
  }

  .faq-mobile-border {
    @apply border-l border-r border-b rounded-b-xl border-l-ashShade-0 border-r-ashShade-0 border-b-ashShade-0 md:border-l-0 md:border-r-0 md:border-b-0;
  }

  @media (min-width: 1440px) {
    .home-hero {
      @apply w-[650px] h-[558px] !important;
    }
    .testimonial-container {
      min-width: 1234px;
    }
  }

  @media (min-width: 1400px) {
    .contractor-container {
      @apply pl-0 !important;
    }
    .home-hero-parent {
      @apply pl-0 !important;
    }
    .home-hero-container {
      @apply pl-[calc((100vw-1234px)/2)];
    }
    .live-projects {
      @apply pl-[calc((100vw-1234px)/2)];
    }
  }

  @media (max-width: 375px) {
    .xs-screen {
      @apply px-4 text-xs;
    }
  }

  @media (max-width: 320px) {
    .xxs-screen {
      @apply gap-2;
    }
  }
}

@font-face {
  font-family: Regular;
  src: url(./fonts/TT\ Commons\ Regular.otf);
}

@font-face {
  font-family: DemiBold;
  src: url(./fonts/TT\ Commons\ DemiBold.otf);
}

@font-face {
  font-family: Bold;
  src: url(./fonts/TT\ Commons\ Bold.otf);
}

@font-face {
  font-family: Medium;
  src: url(./fonts/TT\ Commons\ Medium.otf);
}

@font-face {
  font-family: SatoshiLight;
  src: url(./fonts/Satoshi-Light.otf);
}

@font-face {
  font-family: SatoshiRegular;
  src: url(./fonts/Satoshi-Regular.otf);
}

@font-face {
  font-family: SatoshiMedium;
  src: url(./fonts/Satoshi-Medium.otf);
}

@font-face {
  font-family: SatoshiBold;
  src: url(./fonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: "Regular";
  src: url(./assets/fonts/Satoshi-Regular.otf);
}
@font-face {
  font-family: "Medium";
  src: url(./assets/fonts/Satoshi-Medium.otf);
}

@font-face {
  font-family: "SemiBold";
  src: url(./assets/fonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: "satoshi";
  src: url(./assets/fonts/Satoshi-Variable.woff2) format("woff2");
  font-display: swap;
  font-weight: 100 1000;
}

body {
  margin: 0;
  font-family: Regular;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Height Animation */
.zero-height {
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.auto-height {
  max-height: 500px;
  transition: max-height 1s ease-in-out;
}

@layer components {
  .referral-container {
    @apply max-w-[1234px] mx-auto;
  }
}
